.fpg-info h4 {
    @apply flex justify-start items-center;
}

.fpg-info h4 i {
    @apply text-2xl mr-4;
}

.button {
    @apply relative transition-all rounded-lg px-2 md:px-4 py-2;
}

.button:before {
    @apply invisible dark:bg-white dark:bg-opacity-0 bg-teal-500 bg-opacity-0 rounded-lg transition-all;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(85%);
}

.button:hover:before {
    @apply bg-opacity-10 visible;
    transform: scale(100%);
}

.button.primary {
    @apply bg-teal-400 dark:bg-white outline-teal-400 text-white dark:text-viridian-700;
}

.button.primary:before {
    @apply dark:bg-teal-500 dark:bg-opacity-0 bg-white bg-opacity-0;
}

.button.primary:hover:before {
    @apply bg-opacity-10;
}

.arrow_box {
    position: relative;
}

.arrow_box:after, .arrow_box:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.arrow_box.arrow_right:after, .arrow_box.arrow_right:before {
    left: auto;
    right: 15%;
}

.arrow_box:after {
    @apply border-b-white dark:border-b-viridian-med;
    border-width: 0.4rem;
    margin-left: -0.4rem;
}

.arrow_box:before {
    @apply dark:border-b-viridian-500 border-b-black dark:border-opacity-5 border-opacity-5;
    border-width: 0.5rem;
    margin-left: -0.5rem;
}
