@import 'tailwindcss/base';
@import 'tailwindcss/components';

@layer base {
    body {
        @apply text-viridian-800 dark:text-gray-100 antialiased;
    }

    a {
        @apply text-teal-400 hover:text-teal-600 dark:text-viridian-lite dark:hover:text-white;
    }

    a:focus {
        @apply outline-viridian-400;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply font-header;
    }

    h1 {
        @apply text-4xl mb-8 text-teal-500 dark:text-white;
    }

    h2 {
        @apply text-3xl mb-6;
    }

    h3 {
        @apply text-2xl mb-4;
    }

    h4 {
        @apply text-xl mb-2;
    }

    h5 {
        @apply text-lg mb-1;
    }

    h6 {
        @apply text-base;
    }

    [type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select {
        @apply rounded dark:text-white dark:border-viridian-600 border-viridian-200 dark:bg-viridian-800 focus:border-viridian-400 focus:ring-viridian-400;
    }

    [type='checkbox'], [type='radio'] {
        @apply rounded dark:text-white dark:border-viridian-600 border-viridian-200 dark:bg-viridian-800 focus:border-viridian-400 focus:ring-viridian-400;
    }
}
@import '_base.css';
@import '_site.css';

@import 'tailwindcss/utilities';
