.main-header {
    @apply px-4 flex justify-between py-2 items-center;
}

.main-header .top-nav {
    @apply space-x-2 flex items-center;
}

.main-header .top-nav .button {
    @apply flex items-center;
}

.main-header .top-nav .button i {
    @apply text-2xl;
}

.main-header .top-nav .button p > span {
    @apply hidden lg:block ml-2 whitespace-nowrap;
}

.main-header .top-nav .button p > span:first-child {
    @apply text-xs lg:!text-xs;
}

.main-header .top-nav .button p {
    @apply leading-none;
}

.main-header .top-nav .button p > span.main {
    @apply hidden sm:block lg:text-base text-lg !leading-none;
}

header nav .nav {
    @apply px-4;
    background: linear-gradient(90deg, theme('colors.teal.500') 30%, rgba(255, 255, 255, 0)),
                linear-gradient(90deg, rgba(255, 255, 255, 0), theme('colors.teal.500') 70%) 100% 0,
                radial-gradient(farthest-side at 0 50%, rgba(255, 255, 255, .3), rgba(255, 255, 255, 0)),
                radial-gradient(farthest-side at 100% 50%, rgba(255, 255, 255, .3), rgba(255, 255, 255, 0)) 100% 0;
    background-color: theme('colors.teal.500');
    background-repeat: no-repeat;
    background-size: 40px 100%, 40px 100%, 10px 100%, 10px 100%;
    background-attachment: local, local, scroll, scroll;
}
.dark header nav .nav {
    background: linear-gradient(90deg, theme('colors.viridian.800') 30%, rgba(255, 255, 255, 0)),
    linear-gradient(90deg, rgba(255, 255, 255, 0), theme('colors.viridian.800') 70%) 100% 0,
    radial-gradient(farthest-side at 0 50%, rgba(255, 255, 255, .3), rgba(255, 255, 255, 0)),
    radial-gradient(farthest-side at 100% 50%, rgba(255, 255, 255, .3), rgba(255, 255, 255, 0)) 100% 0;
    background-color: theme('colors.viridian.800');
    background-repeat: no-repeat;
    background-size: 40px 100%, 40px 100%, 10px 100%, 10px 100%;
    background-attachment: local, local, scroll, scroll;
}
header nav .nav {
    @apply overflow-hidden overflow-x-scroll lg:overflow-visible;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

header nav .nav::-webkit-scrollbar {
    display: none;
}

header nav li a {
    @apply whitespace-nowrap text-white p-2 hover:text-white hover:text-opacity-80 block;
}

header nav .nav > li > div {
    @apply hidden absolute z-50 w-full left-0 px-4;
}
header nav .nav > li:hover > div {
    @apply block;
}

header nav .nav > li > div > ul {
    @apply rounded-b-lg p-4 shadow-lg bg-white dark:bg-viridian-800;
    column-count: 4;
    column-gap: 1em;
}
header nav .nav > li > div > ul > li {
    display: grid;
    grid-template-rows: 1fr auto;
    break-inside: avoid;
}
header nav .nav > li > div a {
    @apply text-teal-600 hover:text-teal-400 dark:text-viridian-lite dark:hover:text-white;
}
header nav ul li li div {
    @apply px-2;
}
header nav ul li li ul {
    @apply rounded-md bg-teal-400 dark:bg-viridian-200 dark:bg-opacity-20 bg-opacity-10 p-2;
}

@media (max-width: 1023px) {
    .main-header .top-nav .button .basket-count {
        @apply !block absolute top-2 left-2 md:left-4 w-5 text-center h-4 rounded bg-teal-400 text-white dark:bg-viridian-50 dark:text-viridian-700;
    }

    .main-header .top-nav .button:hover .basket-count {
        @apply bg-teal-500 dark:bg-white;
    }

}

.container {
    @apply max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl w-full px-4 mx-auto;
}

.opening-time {
    @apply w-full space-y-1;
}

.opening-time div {
    @apply flex justify-between;
}

.opening-time span:first-child {
    @apply w-full;
}

.opening-time span:last-child {
    @apply whitespace-nowrap;
}

.opening-time .selected {
    @apply bg-viridian-50 dark:bg-viridian-600 shadow rounded-full px-4 py-2 -mx-4 text-black dark:text-white;
}

.social-icon i {
    @apply text-xl;
}

.footer-links {
    @apply container flex justify-between flex-wrap gap-y-6;
}

.footer-links > div {
    @apply w-1/2 lg:w-auto text-center lg:text-left;
}

.footer-copy {
    @apply pb-4 bg-teal-500 text-white dark:bg-viridian-900;
}

.footer-copy .social-icon {
    @apply text-white;
}

.ui.modal {
    display: none;
}
